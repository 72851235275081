module.exports = {
	"processor_accounts": {
		"title": "Processor accounts",
		"details": "Details",
		"editing": "Editing",
		"creating": "Creating",
		"config": "Config",
		"config_account_data": "Config account data",
		"config_external_data": "External data",
		"create_success": "Account has successfully been created",
		"update_success": "Account has successfully been updated",
		"delete_success": "Account has successfully been deleted",
		"validation_errors": "Validation errors",
		"invalid_processor_name": "Invalid processor",
		"invalid_channel_name": "Channel can't be empty",
		"invalid_title": "Title can't be empty",
		"invalid_account": "Invalid account",
		"required": "Required",
		"new": "New account"
	}
};